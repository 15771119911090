// rollover
var preLoadImg = new Object();
 
function initRollOvers(){
  $("img.imgover,input.imgover").each(function(){
    var imgSrc = this.src;
    var sep = imgSrc.lastIndexOf('.');
    var onSrc = imgSrc.substr(0, sep) + '_o' + imgSrc.substr(sep, 4);
    preLoadImg[imgSrc] = new Image();
    preLoadImg[imgSrc].src = onSrc;
    $(this).hover(
      function() { this.src = onSrc; },
      function() { this.src = imgSrc; }
    );
  });
}
$(function(){
  initRollOvers();
});


// smoothScroll
var targetIdHold = jQuery(this.hash);

jQuery.easing.quart = function (x, t, b, c, d) {
  return -c * ((t = t / d - 1) * t * t * t - 1) + b;
};

jQuery(document).ready(function () {
  jQuery('a[href*="#"]').click(function () {
    let targetId = jQuery(this.hash);
    let scroll = $(window).scrollTop();

    if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
      let target = jQuery(this.hash);
      target = target.length && target || jQuery('[name=' + this.hash.slice(1) + ']');
      let headerH = $('#header').innerHeight();
      let adjust = 0; // ターゲット先へスクロール移動

      if (target.length) {
        let targetOffset = target.offset().top - headerH - adjust;

        jQuery('html,body').animate({
          scrollTop: targetOffset
        }, 1200, 'swing');

        // ターゲット先がスライドパネルの場合はオープン
        if (target.next(".js-slideBox").find(".js-slideBtn").hasClass('is-active')) {} else {
          target.next(".js-slideBox").find(".js-slideBtn").addClass('is-active');
          target.next(".js-slideBox").find(".js-slideBtn").next(".js-slidePanel").slideToggle(300);
        }

        return false;
      }
    }
  });
});

// 他ページからのsmoothScroll
$(function () {
  /* 現在のページURLの?anchor部分を取得
   * l-wrapにoverflow: hidden;を指定しているため、他ページからのアンカーリンクではハッシュタグを使用することができない。
   * そのため、「?anchor=id」という指定をすることで、ハッシュタグを使わずにアンカーリンクを実現する必要がある */
  let para = location.search;
  let headerH = $('#header').innerHeight();

  if (para.indexOf("?anchor=") != -1) {
    let anchor = para.split("?anchor=");
    let target = $('#' + anchor[anchor.length - 1]);

    setTimeout(function () {
      let position = target.offset().top - headerH;

      $("html, body").animate({
        scrollTop: position
      }, 1200, "swing");
    }, "1");
  }
});


// slide btn
$(function(){
  $(".js-slideBtn").each(function(index,element) {
    $(element).click(function() {
      $(this).toggleClass('is-active');
      $(element).next(".js-slidePanel").slideToggle(300);
    });
  });

  // ハッシュがあるときはオープン
  if (window.location.hash.length>0) {
    if ($(window.location.hash).next(".js-slideBox").find(".js-slideBtn").hasClass('is-active')) {
    } else {
      $(window.location.hash).next(".js-slideBox").find(".js-slideBtn").toggleClass('is-active');
      $(window.location.hash).next(".js-slideBox").find(".js-slideBtn").next(".js-slidePanel").slideToggle(300);
    }
  }
});


// header scroll settings
$(window).on('load scroll resize', function(){
  var headerPosTop = 30;  //ヘッダーの初期位置
  
  var winW = $(window).width();
  var winIW = window.innerWidth;  /* スクロールバーを含む幅 */
  var hd = $('#header');
  var contentTop = $('#hero');
  var gnav = $('#gnavfix');
  var logo = $('#js-hdlogo');
  var gnavBtns = $('#ganvBtn');
  var scroll = $(window).scrollTop();

  if ( scroll == 0 && winIW <= 799 ) {
    hd.removeClass('js-isscroll');
    hd.addClass('js-noscroll');
  } else if( scroll < headerPosTop && winIW > 799 ) {
    hd.removeClass('js-isscroll');
    hd.addClass('js-noscroll');
  } else if ( winIW > 799 ) {
    hd.removeClass('js-noscroll');
    hd.addClass('js-isscroll');
  } else {
    hd.removeClass('js-noscroll');
    hd.addClass('js-isscroll');
  }
});


// バーガーメニュー開閉
$(function(){
  $("#js-drawerOpen").click(function() {
    if ($("#js-drawerOpen").hasClass('is-open')) {
      $("#js-drawerOpen").removeClass('is-open');
      $("#drawer").removeClass('is-open');
      $("#gnav").removeClass('is-open');
      $("#js-drawerOpen").addClass('is-close');
      $("#drawer").addClass('is-close');
      $("#gnav").addClass('is-close');
    } else {
      $("#js-drawerOpen").removeClass('is-close');
      $("#drawer").removeClass('is-close');
      $("#gnav").removeClass('is-close');
      $("#js-drawerOpen").addClass('is-open');
      $("#drawer").addClass('is-open');
      $("#gnav").addClass('is-open');
    }
  });
});

$(window).on('resize', function(){
  let winIW = window.innerWidth;

  //PC時は、ドロワーはis-open、is-closeクラスを削除する
  if ( winIW >= 800 ) {
    $("#js-drawerOpen").removeClass('is-open');
    $("#drawer").removeClass('is-open');
    $("#gnav").removeClass('is-open');
    $("#js-drawerOpen").removeClass('is-close');
    $("#drawer").removeClass('is-close');
    $("#gnav").removeClass('is-close');
  }
});

//メニュークリック
$(function(){
  $(".js-gnavItem").each(function(index,element) {
    $(element).click(function() {
      if ( $("#js-drawerOpen").hasClass('is-open') ) {
        $("#js-drawerOpen").removeClass('is-open');
        $("#drawer").removeClass('is-open');
        $("#gnav").removeClass('is-open');
      }
    });
  });

  // ハッシュがあるときはオープン
  if (window.location.hash.length>0) {
    if ($(window.location.hash).next(".js-slideBox").find(".js-slideBtn").hasClass('is-active')) {
    } else {
      $(window.location.hash).next(".js-slideBox").find(".js-slideBtn").toggleClass('is-active');
      $(window.location.hash).next(".js-slideBox").find(".js-slideBtn").next(".js-slidePanel").slideToggle(300);
    }
  }
});


$(window).on('load scroll resize', function(){  
  let winW    = $(window).width();
  let winIW = window.innerWidth;        //スクロールバーを含む幅 
  let winH    = $(window).height();  //ウィンドウの高さ
  let scroll  = $(window).scrollTop();
  let fadeinitem  = $('.js-fadeinitem');
  let fadeinupitem  = $('.js-fadeinupitem');
  let slideupitem  = $('.js-slideupitem');
  let slideupitems  = $('.js-slideupitems');
  let bloomingitem  = $('.js-bloomingitem');
  let revealtext  = $('.js-revealtext');
  let blurwhitem  = $('.js-blurwhitem');
  let charupitem  = $('.js-charupitem');

  //要素フェードイン
  fadeinitem.each(function(){
    let fiiTop = $(this).offset().top;

    // 要素が画面の上から80%(下から20%)に来た時に動く
    let fadeinitemPos = fiiTop - (winH * 0.8);

    if (scroll > fadeinitemPos) {
      $(this).addClass("js-fadein");
    }
  });

  //要素フェードイン&アップ
  fadeinupitem.each(function(){
    let fiuiTop = $(this).offset().top;

    // 要素が画面の上から80%(下から20%)に来た時に動く
    let fadeinupitemPos = fiuiTop - (winH * 0.8);

    if (scroll > fadeinupitemPos) {
      $(this).addClass("js-fadeinup");
    }
  });

  //要素スライドアップ
  slideupitem.each(function(){
    let suiTop = $(this).offset().top;

    // 要素が画面の上から80%(下から20%)に来た時に動く
    let slideupitemPos = suiTop - (winH * 0.8);

    if (scroll > slideupitemPos) {
      $(this).addClass("js-slideup");
    }
  });

  //配下の複数要素連続スライドアップ
  slideupitems.each(function() {
    let suiTop = $(this).offset().top;

    // 要素が画面の上から80%(下から20%)に来た時に動く
    let slideupitemsPos = suiTop - (winH * 0.8);

    if (scroll > slideupitemsPos) {
      $(this).addClass("js-slideups");
    }
  });

  //要素CharUp表示
  charupitem.each(function(){
    let cuTop = $(this).offset().top;

    // 要素が画面の上から100%(下から0%)に来た時に動く
    let charupitemPos = cuTop - (winH * 1.0);

    if (scroll > charupitemPos) {
      $(this).addClass("js-charup");
    }
  });

  //要素テキスト色Blooming
  bloomingitem.each(function(){
    let bmiTop = $(this).offset().top;

    // 要素が画面の上から90%(下から10%)に来た時に動く
    let bloomingitemPos = bmiTop - (winH * 0.9);

    if (scroll > bloomingitemPos) {
      $(this).addClass("js-isblooming");
    }
  });

  //CONTACT→フッター連動
  //CONTACTがある場合は、CONTACTフェードイン→フッタースライドアップ
  if ( $('.js-conjunctionitem1').length ) {
   let cj1Top = $('.js-conjunctionitem1').offset().top;

    // 要素が画面の上から80%(下から20%)に来た時に動く
    let cj1itemPos = cj1Top - (winH * 0.8);

    if (scroll > cj1itemPos) {
      $('.js-conjunctionitem1').addClass("js-fadeIn");
      
      if ( $('.js-conjunctionitem1_step2').length ) {
        setTimeout(function () {
          $('.js-conjunctionitem1_step2').addClass("js-slideup");
        }, "500");
      }
    }

  //CONTACTがない場合は、フッタースライドアップ
  } else {
    if ( $('.js-conjunctionitem1_step2').length ) {
      let cj1_step2Top = $('.js-conjunctionitem1_step2').offset().top;
  
      // 要素が画面の上から80%(下から20%)に来た時に動く
      let cj1_step2itemPos = cj1_step2Top - (winH * 0.8);

      if (scroll > cj1_step2itemPos) {
        $('.js-conjunctionitem1_step2').addClass("js-slideup");
      }
    }
  }
});


//fadein
$(window).scroll(function (){
  $('.js-fadeIn').each(function(){
    var elemPos = $(this).offset().top;
    var scroll = $(window).scrollTop();
    var windowHeight = $(window).height();
    if (scroll > elemPos - windowHeight - 30){
        $(this).addClass('js-scrollIn');
    }
  });
});


// 電話番号リンク
if (navigator.userAgent.match(/(iPhone|iPad|iPod|Android)/)) {
  $(function() {
    $('.js-tellink').each(function() {
      var str = $(this).html();

      if ($(this).children().is('img')) {
        $(this).html($('<a>').attr('href', 'tel:' + $(this).children().attr('alt').replace(/[^0-9]/g, '')).append(str + '</a>')); //対象文字列内から数字だけ取り出してhrefにセット
      } else {
        $(this).html($('<a>').attr('href', 'tel:' + $(this).text().replace(/[^0-9]/g, '')).append(str + '</a>')); //対象文字列内から数字だけ取り出してhrefにセット
      }
    });
  });
}
